.Decoration {
  margin-bottom: -1px;
  position: relative;
  .WaveSvg {
    max-height: 174px;
  }
  .Decoration1Icon,
  .Decoration2Icon,
  .Decoration3Icon {
    position: absolute;
    animation: decoration 20s var(--easing) infinite both;
    transform-origin: center 75%;
  }
  @keyframes decoration {
    0%,
    50%,
    100% {
      transform: scale(0);
    }
    2.5%,
    47.5% {
      transform: scale(1);
    }
  }
  .Decoration1Icon {
    width: clamp(91px, calc(165vw / 7.68), 165px);
    left: 25%;
    top: calc(clamp(91px, calc(165vw / 7.68), 165px) * -0.5);
  }
  .Decoration2Icon {
    width: clamp(61px, calc(111vw / 7.68), 111px);
    right: 9%;
    top: 0;
    animation-delay: -5s;
  }
  .Decoration3Icon {
    width: clamp(72px, calc(118vw / 7.68), 118px);
    left: 8%;
    top: -25%;
    animation-delay: -13s;
  }
}

.Footer {
  background: var(--primary-300, #fff6f9);
  padding-bottom: 48px;
  @media (max-width: 499px) {
    padding-bottom: 64px;
  }
  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 42px;
    justify-content: space-between;
    @media (max-width: 899px) {
      .copyright {
        order: 1;
      }
    }
    .legal {
      display: flex;
      align-items: center;
      gap: 22px 28px;
      flex-wrap: wrap;
    }
  }
}
